.product-display{
    width: 1100px !important;
    margin: 50px ;
    .selected-product{
        display: flex;
        gap: 120px;
        margin-bottom: 100px;
        .product-images{
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 50%;
            #main-image{
            height: 500px !important;
            width: 100%;
            object-fit: cover;
            }
            .sub-images{
                display: grid;
                gap: 20px;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                img{
                    height: 110px;
                    width: 110px;
                    object-fit: cover;
                }
            }
        }
      
        .product-details{
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            height: 100%;
            overflow-y:scroll ;
            -webkit-overflow-scrolling: touch;
            height: 600px;

            p{
                color: grey;
            }
            .selected-product-price{
               display: flex; 
               font-size: 18px;
               gap: 20px;
               .selected-price{
                color: grey;
               }
            }
            .sale-container{
                width: 53px;
                height: 25px;
                background-color: black;
                color: aqua;
                border-radius: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .color-selection{
                display: flex;
                flex-direction: column;
                gap: 10px;
                h6{
                    color: grey;
                }
                .colors{
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    gap: 10px;
                    .color-box{
                        border: 1px solid black;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 25px;
                    }
                }
            }
            .counting-box{
                display: flex;
                gap: 20px;
                border: 1px solid black;
                width: 150px;
                height: 50px !important;
                align-items: center;
                justify-content: space-between;
                padding: 10px 20px;
                button{
                    background-color: transparent;
                    border: none;
                    outline: none;
                }
            }
            .add-buy-buttons{
                display: flex;
                flex-direction: column;
                gap: 20px;
                button{
                    height: 50px;
                }
            }
            .selected-product-details{
                display: flex;
                flex-direction: column;
                gap: 15px;
                h6{
                    color: grey;
                }
            }
        }
        .product-details::-webkit-scrollbar {
            display: none;
        }
    }
   
}
 //recommended products......
 .recommended-products{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h4{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  // width: 1100px
  }
  .product-display {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    padding: 10px;

    .product-card {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .product-image-container {
        position: relative;

        img.default {
          height: 290px;
          width: 100% !important;
          object-fit: cover;
          opacity: 1;
          transition: opacity 0.5s ease-in-out; 
        }

        img.hover {
          display: block;
          height: 290px;
          width: 100% !important;
          object-fit: cover;
          opacity: 0;
          transition:  0.3s ease-in-out;
          position: absolute;
          top: 0;
          left: 0;
        }

        .sale-container {
          position: absolute;
          bottom: 5px;
          left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          background-color: black;
          border-radius: 25px;
          color: aqua;
          font-size: 15px;
        }

        &:hover {
          img.default {
            opacity: 0;
          }

          img.hover {
            opacity: 1;
          }
        }
      }

      .product-name {
          font-size: 14px;
          color: grey;
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

      .product-price-details {
        display: flex;
        gap: 20px;
        align-items: center;

        .price {
          color: lightgray;
          font-size: 15px;

          span {
            margin-right: 10px;
          }
        }

        .discounted-price {
          font-size: 16px;
          font-weight: 400;

          span {
            margin-right: 10px;
          }
        }
      }
       &:hover{
      .product-name{
          h6{
              display: inline-block;
               border-bottom: 1px solid black;
          }
      }
    }
    }
   
  }
}

@media(max-width:1100px){
.product-display{
  width: 100% !important;
  .selected-product{
    justify-content: space-between;
    .product-images{
      width: 400px;
      .sub-images{
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    .product-details{
      width: 100% !important;
    }
  }
}
   
}
@media(max-width:995px){
.product-display{
  max-width: 1100px !important;
  justify-content: unset;
  gap: 20px;
  .selected-product{
    gap: 20px;
    .product-images{
      // width: 50%;
      .sub-images{
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    .product-details{
      width: 100% !important;
    }
  }
}   
}

@media(max-width:740px){
.product-display{
  .selected-product{
    flex-direction: column;
    .product-images{
      width: 100%;
      .sub-images{
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }
}  
.recommended-products{

 
  .product-display {
    grid-template-columns:1fr 1fr;
   
    .product-card {
      align-items: center;
    justify-content: center;

      .product-image-container {
        position: relative;
        
        img.default {
          height: 290px;
          width: 200px !important;
          object-fit: cover;
          opacity: 1;
          transition: opacity 0.5s ease-in-out; 
        }

      }
    }
   
  }
} 
}
@media(max-width:540px){
.product-display{
  .selected-product{
    flex-direction: column;
    .product-images{
      display: flex;
      align-items: center;
      justify-content: center;
      // height: 550px !important;

      img{
        width: 400px !important;
      }
      .sub-images{
        grid-template-columns: 1fr 1fr 1fr;
        img{
          width: 110px !important;
          height: 110px !important;
        }
      }
    }
    .product-details{
      margin-top: 50px;
    }
  }
}   
}
@media(max-width:430px){
  .product-display{
    .selected-product{
      .product-images{
        
  .carousel{
    width: 100% !important;
    .section-images{
      margin: auto;
      width: 100%;
      img{
        width: 100% !important;
        height: 100% !important;
      }
    }
    .thumb{
      display: none;
    }
  }
        img{
          height: 100% !important;
        }
       
      }
      .product-details{
        margin-top: 20px;
      }
    }
  } 
   .recommended-products{
 
    .product-display {
      grid-template-columns:1fr 1fr;
      // width: 350px !important;
      margin: unset;
     
      .product-card {
      align-items: center;
      justify-content: center;
      // height: 200px;

      
  
        .product-image-container {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          
          img.default {
            width: 90% !important;
            object-fit: cover;
            opacity: 1;
            transition: opacity 0.5s ease-in-out; 
            height: 120px;

          }
          img.hover{
            height: 120px;
          }
  
        }
      }
     
    }
  } 

}
