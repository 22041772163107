/* General Styles */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    margin: 0;
    padding: 0;
  }
  
  .container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
  }
  
  h1, h2, h3 {
    color: #0056b3;
  }
  
  h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  h2 {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  h3 {
    font-size: 1.2em;
    margin-top: 20px;
  }
  
  p {
    margin: 10px 0;
  }
  
  ul {
    margin: 10px 0;
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 5px;
  }
  
  a {
    color: #0056b3;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  .footer {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #ddd;
  }
  
  @media (max-width: 768px) {
    .container {
      width: 95%;
    }
  }
  